import React from 'react';

import Layout from '../../components/MenuLayout';
import { ProtocolsView } from '../../components/Protocols/ProtocolsView';

export default () => (
  <Layout>
    <ProtocolsView />
  </Layout>
);
