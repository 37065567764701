/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import Loading from '../Common/Loading';
import { useStyles } from './styled/ProtocolMakeStyles';
import { parseGraphqlErrors } from '../../utils/FormikUtils';

import { GET_PROTOCOLS, GET_PROTOCOL, CREATE_PROTOCOL } from '../../queries/Protocols/Protocols';
import { isEmpty } from '../../utils/ObjectUtils';
import { ProtocolFormDialog } from './ProtocolFormDialog';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ProtocolEditDialog = ({ open, onClose, protocolId }) => {
  if (!protocolId) return null;

  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState(false);

  const { loading, data } = useQuery(
    GET_PROTOCOL,
    { variables: { uuid: protocolId } },
  );

  const [createProtocol, { loading: updating }] = useMutation(
    CREATE_PROTOCOL,
    {
      onCompleted: () => {
        onClose();
      },
      refetchQueries: [{ query: GET_PROTOCOLS, variables: { hospitalUuid } }],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      await createProtocol({ variables: { input: { ...values, ...{ hospitalUuid } } } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const formId = 'edit-protocol-form';

  return (
    <>
      <Dialog
        className={classes.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
      >
        <DialogTitle>{t('edit.protocol')}</DialogTitle>
        <DialogContent>
          {!loading && !isEmpty(data) && data.protocol ? (
            <ProtocolFormDialog
              formId={formId}
              protocol={data.protocol}
              handleSubmit={handleSubmit}
              submitting={updating}
              serverError={serverError}
            />
          ) : <Loading />}
        </DialogContent>
        {updating && <Loading />}
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.button}
            color="primary"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            type="submit"
            form={formId}
            color="primary"
          >
            {t('create.and.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
